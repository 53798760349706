@import './mixin.scss';
@import './variables.scss';

.ai-close-btn {
    position: absolute;
    top: 24px;
    right: 24px;
    border-radius: 4px;
    background-color: $grey_100;
    border: none;
    width: 48px;
    height: 48px;
    @include flexCenter;
    font-size: 24px;
    font-weight: 500;
}

.messages-wrapper {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    overflow-y: auto;

    .messages-wrapper-body {
        width: 790px;
    }
}

.ai-no-messages-wrapper {
    width: 100%;
    height: 100%;
    @include flexCenter;

    .ai-no-messages-text-contain {
        display: flex;
        justify-content: center;
        position: relative;

        .ai-no-messages-textarea {
            width: 560px;
            border-radius: 14px;
            padding: 14px;
            background-color: $grey_200;
            border: none;

            &:focus {
                outline: 1px solid $color_green;
            }
        }

        .ai-no-messages-btn {
            position: absolute;
            top: 8px;
            right: 8px;
            width: 36px;
            height: 36px;
            padding: 0;
            @include flexCenter;
            background-color: $color_green;
            color: $color_white;
            font-weight: 500;
            font-size: 14px;
            border: none;
            border-radius: 8px;
        }
    }
}

.ai-messages-wrapper {
    width: 100%;
    height: 100%;

    .ai-messages-list {
        padding-bottom: 180px;

        .ai-messages-list-msgs {
            list-style: none;
            padding: 24px 16px;

            .ai-messages-system {
                margin-bottom: 16px;

                .ai-messages-info {
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    margin-bottom: 4px;

                    .ai-messages-info-user {
                        font-size: 14px;
                        font-weight: 500;
                        color: $grey_500;
                        margin-bottom: 0;
                    }

                    .ai-messages-info-timestamp {
                        font-size: 10px;
                        color: $grey_400;
                        margin-bottom: 0;
                    }
                }

                .ai-messages-system-conversation {
                    background-color: $grey_100;
                    padding: 12px;
                    border-radius: 14px;
                    max-width: 50%;
                    width: fit-content;
                }
            }

            .ai-messages-client {
                width: 100%;
                margin-bottom: 16px;
                display: flex;
                justify-content: flex-end;

                .ai-messages-info {
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    margin-bottom: 4px;
                    justify-self: flex-end;

                    .ai-messages-info-user {
                        font-size: 14px;
                        font-weight: 500;
                        color: $grey_500;
                        margin-bottom: 0;
                    }

                    .ai-messages-info-timestamp {
                        font-size: 10px;
                        color: $grey_400;
                        margin-bottom: 0;
                    }
                }

                .ai-messages-client-conversation {
                    background-color: $color_green;
                    color: $color_white;
                    padding: 12px;
                    border-radius: 14px;
                    width: fit-content;
                    display: flex;
                    justify-self: flex-end;
                }
            }
        }
    }
}

.ai-bottom-section {
    background-color: #f9f9f9;
    position: absolute;
    bottom: 0;
    width: 100%;
    bottom: 0;

    .ai-messages-textarea-container {
        display: flex;
        justify-content: center;
        width: 100%;
    
        .ai-messages-textarea {
            display: flex;
            justify-content: center;
            width: 790px;
    
            .ai-messages-textarea {
                width: 100%;
                border-radius: 14px;
                padding: 14px;
                background-color: $grey_200;
                border: none;
    
                &:focus {
                    outline: 1px solid $color_green;
                }
            }
    
            .ai-messages-btn {
                position: absolute;
                bottom: 8px;
                right: 8px;
                width: 36px;
                height: 36px;
                padding: 0;
                @include flexCenter;
                background-color: $color_green;
                color: $color_white;
                font-weight: 500;
                font-size: 14px;
                border: none;
                border-radius: 8px;

                &:disabled {
                    opacity: 0.6;
                }
            }
        }
    }
}