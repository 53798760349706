@import './mixin.scss';

th, td {
  border-bottom: 1px dashed #BFC9CA;
  border-collapse: collapse;
}

th, td {
  padding: 15px 10px 15px 10px;
}

.chat-list-header {
  border-bottom: 1px solid $grey_100;
  padding-bottom: 16px;

  &-title {
    margin: 0;
    color: $grey_800;
    font-weight: 500;
    font-size: 18px;
  }

  &-icon {
    height: 32px;
    width: 32px;
    border-radius: 8px;
    border: 1px solid $grey_200;
    @include flexCenter;
  }
}

.search-input-contain {
  background-color: $grey_50;
  border: 1px solid $grey_200;
  border-radius: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding: 0 14px;

  .search-input{
    border-style: hidden;
    width: 100%;
    border: none;
    padding: 12px 0;
    background-color: transparent;
  
    &:focus{
      border-style: hidden;
      outline: none;
    }
  }
}

.chat-list-container{
  margin-top: 20px;
  max-height: 470px;
  min-height: 470px;
  padding-right: 5px;
  overflow-y: scroll;

  &:hover{
    overflow-y: scroll;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--custom-white);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #D4E6F1 ;
    border-radius: 20px;
    border: 1px solid var(--custom-white);
  }

  .active{
    background-color: #D4E6F1 ;
  }

  .single-chat-list-container{
    display: flex;
    flex-direction: row;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;

    .active{
      background-color: #D4E6F1 ;
    }

    &:hover{
      background-color: #D4E6F1 ;
    }

    .user-profile-image{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 5px;
    }

    .info-container{
      width:85%;

      .user-name{
        font-size: 12px;
        font-weight: bold;
        color: $grey_800;
      }

      .time{
        font-size: 10px;
      }

      .message{
        font-size: 12px;
        color: $grey_400;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width:80%
      }

      .counter{
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #A93226;
        color: white;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.empty-chat {
  .empty-chat-icon {
    @include flexCenter;
    background-color: $grey_75;
    border: 1px solid $grey_100;
    border-radius: 100%;
  }

  .empty-chat-text-primary {
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    color: $grey_800;
    margin-bottom: 8px;
  }

  .empty-chat-text-secondary {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: $grey_400;
  }
}

.chat-detail-container{
  .chat-detail-header{
    border-bottom: 1px solid #E5E8E8;
    padding-bottom: 5px;

    .chat-detail-data-container{
      display: flex;
      flex-direction: column;
      flex: 1;

      .name{
        font-size: 15px;
        font-weight: bold;
        color: #566573;
      }

      .phone{
        font-size: 13px;
        margin-top: -2px;
      }
    }
  }

  .chat-detail-body{
    padding-bottom: 20px;
    padding-left: 12px;
    margin-top: 20px;
    max-height: 403px;
    min-height: 403px;
    overflow-y: scroll;
    background-color: $grey_75;
    border-radius: 20px;

    &:hover{
      overflow-y: scroll;
    }
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--custom-white);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #D4E6F1 ;
      border-radius: 20px;
      border: 1px solid var(--custom-white);
    }

    .chat-receiver-text-bubble {
      width: 50%;
      margin-top: 5px;
      padding: 12px;
      border-radius: 12px;
      background-color: $color_white;
      color: $grey_500;
      font-size: 12px;
      font-weight: 500;
    }

    .chat-sender-text-bubble {
      width: 50%;
      margin-top: 5px;
      padding: 12px;
      border-radius: 12px;
      background-color: #44BC86;
      color: $color_white;
      font-size: 12px;
      font-weight: 500;
    }

    .chat-timestamp {
      font-size: 10px;
      margin-top: 12px;
      font-weight: 400;
      display: flex;
      justify-content: flex-end;

      &-sender {
        color: $grey_100;
      }

      &-receiver {
        color: $grey_400;
      }
    }
  }
}

.whatsapp-contain {
  height: 500px;

  .illustrate-contain {
    width: 50%;

    @include for-phone-only {    
      width: 100%;
    }
  }

  .whatsapp-illustration {
    width: 450px;

    @include for-phone-only {    
      width: 325px;
      height: 250px;
    }
  }

  @include for-phone-only {    
    height: 100%;
  }
}

.chat-send {
  display: flex;
  column-gap: 8px;
  margin-top: 16px;

  .chat-send-input-container {
    width: 100%;
  
    .chat-send-search-input {
      width: 100%;
      background-color: $grey_50;
      border: 1px solid $grey_200;
      font-size: 14px;
      padding: 10px 12px;
      border-radius: 8px;
    }
  }
}

.whatsapp-templates-title {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  color: $grey_800;
}

.whatsapp-templates-msg {
  max-width: 467px;
  white-space: wrap;
}

.blur-top {
  position: absolute;
  z-index: 2;
  top: 50;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: transparent;
  backdrop-filter: blur(10px);
}