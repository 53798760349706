//@font-face {
//  font-family: 'DM Serif Display Regular';
//  font-style: normal;
//  font-weight: normal;
//  src: local('DM Serif Display Regular'), url('fonts/DMSerifDisplay-Regular.woff') format('woff');
//}
@import './variables.scss';

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap');
@import './mixin.scss';

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-SemiBoldItalic.eot');
  src: local('Fellix SemiBold Italic'), local('Fellix-SemiBoldItalic'),
      url('../assets/Fellix/Fellix-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-SemiBoldItalic.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-SemiBoldItalic.woff') format('woff'),
      url('../assets/Fellix/Fellix-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-SemiBold.eot');
  src: local('Fellix SemiBold'), local('Fellix-SemiBold'),
      url('../assets/Fellix/Fellix-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-SemiBold.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-SemiBold.woff') format('woff'),
      url('../assets/Fellix/Fellix-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-Light.eot');
  src: local('Fellix Light'), local('Fellix-Light'),
      url('../assets/Fellix/Fellix-Light.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-Light.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-Light.woff') format('woff'),
      url('../assets/Fellix/Fellix-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-Black.eot');
  src: local('Fellix Black'), local('Fellix-Black'),
      url('../assets/Fellix/Fellix-Black.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-Black.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-Black.woff') format('woff'),
      url('../assets/Fellix/Fellix-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-BlackItalic.eot');
  src: local('Fellix Black Italic'), local('Fellix-BlackItalic'),
      url('../assets/Fellix/Fellix-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-BlackItalic.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-BlackItalic.woff') format('woff'),
      url('../assets/Fellix/Fellix-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-ExtraBold.eot');
  src: local('Fellix ExtraBold'), local('Fellix-ExtraBold'),
      url('../assets/Fellix/Fellix-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-ExtraBold.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-ExtraBold.woff') format('woff'),
      url('../assets/Fellix/Fellix-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-ThinItalic.eot');
  src: local('Fellix Thin Italic'), local('Fellix-ThinItalic'),
      url('../assets/Fellix/Fellix-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-ThinItalic.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-ThinItalic.woff') format('woff'),
      url('../assets/Fellix/Fellix-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-Medium.eot');
  src: local('Fellix Medium'), local('Fellix-Medium'),
      url('../assets/Fellix/Fellix-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-Medium.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-Medium.woff') format('woff'),
      url('../assets/Fellix/Fellix-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-LightItalic.eot');
  src: local('Fellix Light Italic'), local('Fellix-LightItalic'),
      url('../assets/Fellix/Fellix-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-LightItalic.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-LightItalic.woff') format('woff'),
      url('../assets/Fellix/Fellix-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-BoldItalic.eot');
  src: local('Fellix Bold Italic'), local('Fellix-BoldItalic'),
      url('../assets/Fellix/Fellix-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-BoldItalic.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-BoldItalic.woff') format('woff'),
      url('../assets/Fellix/Fellix-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-Thin.eot');
  src: local('Fellix Thin'), local('Fellix-Thin'),
      url('../assets/Fellix/Fellix-Thin.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-Thin.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-Thin.woff') format('woff'),
      url('../assets/Fellix/Fellix-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-Regular.eot');
  src: local('Fellix Regular'), local('Fellix-Regular'),
      url('../assets/Fellix/Fellix-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-Regular.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-Regular.woff') format('woff'),
      url('../assets/Fellix/Fellix-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-Bold.eot');
  src: local('Fellix Bold'), local('Fellix-Bold'),
      url('../assets/Fellix/Fellix-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-Bold.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-Bold.woff') format('woff'),
      url('../assets/Fellix/Fellix-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-RegularItalic.eot');
  src: local('Fellix Regular Italic'), local('Fellix-RegularItalic'),
      url('../assets/Fellix/Fellix-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-RegularItalic.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-RegularItalic.woff') format('woff'),
      url('../assets/Fellix/Fellix-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-ExtraBoldItalic.eot');
  src: local('Fellix ExtraBold Italic'), local('Fellix-ExtraBoldItalic'),
      url('../assets/Fellix/Fellix-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-ExtraBoldItalic.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-ExtraBoldItalic.woff') format('woff'),
      url('../assets/Fellix/Fellix-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Fellix';
  src: url('../assets/Fellix/Fellix-MediumItalic.eot');
  src: local('Fellix Medium Italic'), local('Fellix-MediumItalic'),
      url('../assets/Fellix/Fellix-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/Fellix/Fellix-MediumItalic.woff2') format('woff2'),
      url('../assets/Fellix/Fellix-MediumItalic.woff') format('woff'),
      url('../assets/Fellix/Fellix-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

body {
  margin: 0;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #74788d !important;
  background-color: $grey_50;
  font-family: "Fellix", sans-serif !important;
  font-size: 14px;
}

.home {
  display: flex;
  overflow: hidden;
}

.main {
  margin-left: 250px;
  margin-top: 60px;
  width: 100%;
  overflow: auto;

  @include for-tablet-down {    
    margin-left: 0;
    margin-right: 0;
  }

  @include for-desktop-sm-up {
    margin-left: 220px;
  }
}

.page {
  min-height: 100vh;
  margin-left: 10px;
  margin-top: 50px;
  // background-color: $color_white !important;
  border-radius: 24px;
  padding: 24px 0;
}

.btn-primary {
  padding: 8px 16px;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  border: 1px solid #0F794A;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: linear-gradient(180deg, #44BC86 0%, #15AB68 66.67%, #139C5F 96.87%);
  box-shadow: 
    0px 1px 3px 0px #0000001F, 
    0px 0.5px 0.5px 0px #00000040, 
    0px 2px 0.5px 0px #B6FFDE4D inset, 
    0px -0.5px 0.5px 0px #0EA460 inset;

  &:disabled {
    background: $grey_300;
    box-shadow: none;
    border: none;
  }
}

.btn-secondary {
  padding: 8px 16px;
  color: #0F794A;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  border: 1px solid #0F794A;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.btn-error {
  padding: 8px 16px;
  color: $error_400;
  font-size: 13px;
  font-weight: 700;
  border-radius: 8px;
  border: 1px solid $error_700;
  background: $error_50;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.btn-error-secondary {
  padding: 8px 16px;
  color: $error_400;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  border: 1px solid $error_700;
  background: $error_50;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

a{
  text-decoration: none;
}

.pr-none {
  padding-right: 0 !important;
}

.search-input-contain {
  width: 300px;
  position: relative;

  @include for-phone-only {    
    width: 100%;
  }

  .search-input {
    background-color: $grey_50;
    border: 1px solid $grey_200;
    border-radius: 50px;
    padding: 8px 12px 8px 32px;
    font-size: 12px;
    width: 100%;

    &:focus {
      outline: 1px solid #15AB68;
    }
  }

  .search-icon {
    @include flexCenter;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #15AB68;
    position: absolute;
    top: 5px;
    left: 5px;
  }
}

.text-input-contain {
  width: 100%;
  position: relative;

  .text-prefix {
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    color: $grey_500;
    top: 13px;
    left: 20px;
  }

  .text-input {
    padding: 12px 20px;
    border-radius: 10px;
    background-color: #FFF;
    border: 1px solid $grey_300;
    width: 100%;
    font-size: 14px;

    &:disabled {
      background-color: $grey_100;
    }

    &:focus {
      outline: 1px solid #15AB68;
    }

  }
  .prefix-input {
    padding-left: 56px !important;
  }

  .password-icon {
    position: absolute;
    top: 19px;
    right: 20px;
    color: $grey_500;
    cursor: pointer;
  }
}

.form-modal {
  width: 618px;
}

.text-green {
  color: $color_green;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: #FFF;
}

.input-check {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1.5px solid #44BC86;
  cursor: pointer;
  @include flexCenter;

  &-checked {
    background-color: #44BC86;
    color: #FFF;
    font-weight: 600;
    font-size: 24px;
  }
}

.radio-check {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1.5px solid $grey_300;
  background-color: #FFF;
  padding: 4px;
  cursor: pointer;

  &-checked {
    background-color: #44BC86;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.PhoneInput {
  width: 100%;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  padding: 11px 16px !important;
  font-size: 14px;
  background-color: #FFF;

  &:disabled {
    background-color: $grey_100;
  }
}

.PhoneInputInput {
  border: none !important;
  padding: 0 !important;

  &:disabled {
    background-color: $grey_100;
  }
}

.PhoneInputInput:focus {
  border: none !important;
  outline: none !important;
}

.select-text-input-contain {
  width: 100%;
  position: relative;

  .text-input {
    padding: 12px 20px;
    border-radius: 10px;
    background-color: #FFF;
    border: 1px solid $grey_300;
    width: 100%;
    font-size: 14px;

    &:disabled {
      background-color: $grey_100;
    }

    &:focus {
      outline: 1px solid #15AB68;
    }

  }

  .select-text-close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 10;
    overflow: hidden !important;
  }

  .select-text-input-contain {
    position: relative;
    
    &-index {
      z-index: 11;
    }
  }

  .select-text-arrow {
    font-size: 12px;
    color: $grey_400;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .select-text-dropdown {
    position: absolute;
    z-index: 4;
    top: 55px;
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 0px 2px 4px -1px #10192805, 0px 5px 13px -5px #1019280D;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;

    .select-text-dropdown-list {
      list-style: none;
      overflow-y: auto;
      height: 100%;
      width: 100%;
      padding: 0;
      margin-bottom: 0;

      li {
        padding: 12px 24px;
        border-bottom: 1px solid $grey_200;
        font-size: 14px;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }
        
        &:hover {
          background-color: $grey_100;
        }
      }
    }
  }
}

.upload-input-contain {
  border: 3px dashed $grey_200;
  border-radius: 8px;
  cursor: pointer;

  .upload-input-wrap {
    padding: 16px 0;
  }

  .upload-img-pic {
    width: 56px;
    height: 40px;
    border: 0.5px solid $grey_200;
  }

  .photo-icon {
    font-size: 40px;
  }

  .upload-img-filename {
    text-align: center;
    font-size: 14px;
    margin-bottom: 0;
  }
}

.upload-input-contain-02 {
  border: 3px dashed $grey_200;
  border-radius: 8px;
  cursor: pointer;

  .upload-input-wrap {
    padding: 0;
  }

  .upload-img-pic {
    width: 56px;
    height: 40px;
    border: 0.5px solid $grey_200;
  }

  .photo-icon {
    font-size: 40px;
  }

  .upload-img-filename {
    text-align: center;
    font-size: 14px;
    margin-bottom: 0;
    padding-top: 5px;
  }
}

.toggle-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 14;
  background: transparent;
}

.toggle-menu-lists {
  overflow: hidden;
  background-color: $color_white;
  box-shadow: 0px 2px 4px -1px #10192805, 0px 5px 13px -5px #1019280D;
  border-radius: 10px;
  position: absolute;
  z-index: 20;
  list-style: none;
  padding-left: 0;
}

.toggle-menu-list-open {
  max-height: 700px;
}

.toggle-menu-list-closed {
  max-height: 0;
}

.toggle-menu-list-item {
  white-space: nowrap;
  padding: 6px 16px;
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.dropdown-contain {
  position: relative;

  .dropdown-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 8;
  }

  .dropdown-list-inactive {
    max-height: 0;
    overflow: hidden;
    position: absolute;
  }

  .dropdown-list-active {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 36px;
    left: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background: $color_white;
    border-radius: 16px;
    min-width: 200px;
    z-index: 9;

    .dropdown-list-contain {
      padding: 20px 16px;

      .dropdown-list {
        list-style: none;

        .dropdown-list-item {
          padding: 14px 0;
          display: flex;
          gap: 8px;
          justify-content: space-between;
          white-space: nowrap;
          align-items: center;
        }
      }
    }
  }
}