@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@600&display=swap');
@import './mixin.scss';
@import './variables.scss';

@font-face {
    font-family: FeatureDisplay;
    src: url(../assets/Feature_Display_Family/FeatureDisplay-Bold-Trial.otf);
}
  
@font-face {
    font-family: FeatureDisplay;
    src: url(../assets/Feature_Display_Family/FeatureDisplay-BoldItalic-Trial.otf);
    font-style: italic;
}

.feature-header {
    font-family: FeatureDisplay !important;
}

.box-container {
    max-width: 550px;
    margin: 100px auto;
    overflow: auto;

    a {
        text-decoration: none;
    }

    @include for-phone-only {    
        padding: 24px;
    }
}

.Auth-Wrap {
    display: flex;
    width: 100%;

    .Auth-Left {
        min-width: 50vw;
    }
    .Auth-Left-Fixed {
        position: fixed;
        left: 0;
        top: 0;
        min-width: 50vw;
        max-width: 50vw;
        background-image: url('../assets/auth/SideIllustrate.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 100px 60px;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .logo-contain {
            background-color: #FFF;
            width: 100%;
            opacity: 88%;
            border-radius: 66px;
            border: 2px solid #EDEDED;
            box-shadow: 0px 10px 18px -2px #10192812;
            padding: 20px 40px;

            .text-redirect {
                font-size: 14px;
                color: $grey_500;
                margin: 0;

                a {
                    color: $color_green;
                    font-weight: 700;
                    font-size: 14px;
                }
            }
        }

        .auth-track-contain {
            background-color: #FFF;
            width: 70%;
            border-radius: 25px;
            border: 2px solid #EDEDED;
            box-shadow: 0px 10px 18px -2px #10192812;
            padding: 20px;
        }
    }

    .Auth-Right {
        width: 100%;
        padding: 120px 0;
        display: flex;
        justify-content: center;
        background-color: hsl(0, 0%, 100%);
        height: 100%;
        overflow-y: auto;



        .Auth-form {
            max-width: 600px;
            min-width: 600px;

            .auth-border {
                margin-top: 40px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                    margin: 0;
                    color: $grey_500;
                    font-size: 14px;
                }

                &-line {
                    width: 100%;
                    height: 1px;
                    background-color: $grey_100;
                }
            }
        }
    }
}

.auth-track-contain {
    display: flex;
    flex-direction: column;
    margin-left: 30px;

    .progress-row {
        display: flex;
        gap: 12px;
        .progress-icon {
            min-width: 32px;
            @include flexCenter;
            &-active {
                min-height: 32px;
                border-radius: 100%;
                border: 1px solid #B6E5D0;
                background-color: #E8F7F0;
                box-shadow: 0px 2.5px 5px -2.5px #B6E5D0, 0px 7.5px 10px -2.5px #68CDA040;
            }

            &-success {
                min-height: 32px;
                border-radius: 100%;
                background-color: #44BC86;

                svg {
                    color: #FFF;
                    font-weight: 700;
                    font-size: 24px;
                }
            }
        }

        .progress-line {
            height: 100%;
            min-width: 2px;
            background-color: #D6F9E4;

            &-active {
                background-color: #62C79A;
            }

        }

        .progress-detail {
            padding-bottom: 20px;

            &-title {
                font-weight: 700;
                color: $grey_900;
                margin-bottom: 6px;
            }

            &-desc {
                font-size: 14px;
                font-weight: 400;
                line-height: 20.3px;
            }
        }
    }
}

.input-container {
    background-color: #D4E6F1;
    padding: 15px;
    border-radius: 3px;
}

.input-contain {
    label {
        font-size: 16px;
        font-weight: 500;
        color: $grey_500;
        margin-bottom: 12px;
    }
}

.password-link {
    font-size: 12px;
    color: $grey_500;
    font-weight: 500;
}

.auth-terms-text {
    font-size: 12px;
    font-weight: 400;
    color: $grey_500;
    margin-top: 8px;
    text-align: center;
}

.login-input {
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #D4E6F1 inset !important;
    }

    &:active,
    &:focus,
    &:focus-within {
        background-color: transparent;
    }
}

.login-button {
    background-color: #008561;
    height: 45px;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.15385;
    margin: 0;
    outline: none;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;

    &:hover{
        background-color: #45B39D;
        box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
    }
}

.inputField {
    border: 1px solid #e0e0e0;
    height: 50px;
    border-radius: 3px;
    font-size: 20px;
    color: #000;
    flex: 1 0 45px;

    @include for-phone-only {    
        flex: 1 0 12px;
        height: 30px;
        font-size: 14px;
    }
}

.enclose {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.Operator-item {
    border: 1px solid #777474;
    border-radius: 6px;
    padding: 1.2rem;
}

// .Operator-item input:checked {
//     color: #008561 !important;
// }

.Operator-header {
    font-weight: 600;
    font-size: 20px;
}

.Active-header {
    color: #008561;
}

.Active-info {
    color: #008561;
}

.Active-Operator {
    border: 1px solid #008561;
}

.auth-service-card {
    width: 100%;
    padding: 24px;
    border-radius: 15px;
    background-color: $grey_75;
    border: 1px solid $grey_200;
    cursor: pointer;

    &-active {
        border-color: #44BC86;
    }

    &-top {
        @include flexItemsCenter;
        justify-content: space-between;
        margin-bottom: 20px;

        p {
            font-weight: 500;
            font-size: 20px;
            color: $grey_700;
            margin: 0;
        }

        input:checkbox {

        }
    }

    &-desc {
        font-size: 12px;
        color: $grey_500;
    }
}

.progress-bar {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin: 20px 0;

    &-inactive {
        background-color: $grey_200;
        height: 8px;
        border-radius: 30px;
        width: 100%;
    }

    &-active {
        background-color: #44BC86;
        height: 8px;
        border-radius: 30px;
        width: 100%;
    }
}

.question-wrap {
    padding: 24px;
    border-radius: 15px;
    background-color: $grey_75;
    border: 1px solid $grey_200;

    .question-text {
        @include flexItemsCenter;
        gap: 12px;
        color: $grey_500;
        font-size: 15px;
        margin-bottom: 18px;

        svg {
            font-weight: 700;
            font-size: 20px;
            color: $grey_400;
        }
    }

    .multi-answer {
        list-style: none;
        padding: 0;

        li {
            @include flexItemsCenter;
            gap: 16px;
            padding: 12px 8px;
            color: $grey_800;
            font-size: 12px;
        }
    }

    .question-textarea {
        width: 100%;
        border: 1px solid $grey_300;
        background-color: #FFF;
        border-radius: 10px;
        padding: 16px;
        color: $grey_600;
    }

    .question-textbox {
        width: 100%;
        border: 1px solid $grey_300;
        background-color: #FFF;
        border-radius: 10px;
        padding: 16px;
        color: $grey_600;
    }
}